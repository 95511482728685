import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { useLangUrlDefault } from "../../../hooks";
import { TextButton } from "../../ui/TextButton/TextButton";

import { StyledAuthNavigate } from "./AuthNavigate.styled";

export const AuthNavigate = ({
  to,
  isStatic = false,
  noIcon = false,
  inReviewResponse = false,
  dataTestId
}) => {
  // **Redux state
  const { t } = useTranslation();
  // **Props
  const [, hrefLang] = useLangUrlDefault();
  return (
    <StyledAuthNavigate isStatic={isStatic} inReviewResponse={inReviewResponse}>
      <span>
        {to === "login" && t("authPages.navigate.toLogin.text")}
        {to === "registration" && t("authPages.navigate.toRegister.text")}
      </span>
      <TextButton
        color="primary"
        size="md"
        iconRight={!noIcon ? "arrowAltRight" : null}
        linkTo={`${hrefLang}/${to}`}
        type="link"
        dataTestId={dataTestId}
      >
        {to === "login" && t("authPages.navigate.toLogin.btn")}
        {to === "registration" && t("authPages.navigate.toRegister.btn")}
      </TextButton>
    </StyledAuthNavigate>
  );
};

AuthNavigate.propTypes = {
  to: PT.oneOf(["login", "registration"]).isRequired,
  isStatic: PT.bool,
  noIcon: PT.bool,
  inReviewResponse: PT.bool,
  dataTestId: PT.string
};
