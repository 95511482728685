import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Title } from "../../../../components/ui/Title/Title";

export const SectionInfo = styled.div`
  overflow: hidden;
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: ${(p) => {
    if (p.variant === "light") {return p.theme.colors["background-color-seo"];}
    if (p.variant === "white") {return p.theme.colors.white;}
    return p.theme.colors["background-color"];
  }};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-top: ${(p) => {
      if (p.variant === "light") {return "94px";}
      return "120px";
    }};
    padding-bottom: 120px;
  }
`;

export const RestyledContainer = styled(Container)`
  display: flex;
  flex-direction: ${(p) =>
    p.variant === "light" ? "column" : "column-reverse"};

  padding-bottom: ${(p) => (p.variant === "light" ? "32px" : "0")};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: ${(p) => (p.variant === "white" ? "flex-start" : "center;")};
    padding-bottom: ${(p) => (p.variant === "light" ? "64px" : "0")};
  }
`;

export const AnimationContainer = styled.div`
  position: relative;
  display: none;
  width: 0;
  height: 0;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: block;

    ${(p) => {
      if (p.variant === "light") {
        return `
        min-width: 438px;
        width: 438px;
        height: 312px;
        `;
      }

      if (p.variant === "white") {
        return `
        width: 343px;
        height: 351px;
        `;
      }

      return `
      min-width: 517px;
      width: 517px;
      height: 529px;
    `;
    }}
`;

export const MobilePicture = styled.picture`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const MobileImage = styled.img`
  width: 100%;
  max-width: 500px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
    width: 0;
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: ${(p) => {
    if (p.variant === "light") {return "0";}
    if (p.variant === "white") {return "32px";}
    return "40px";
  }};
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin: ${(p) => {
      if (p.variant === "light") {return "0 92px 0 0";}
      if (p.variant === "white") {return "0";}
      return "0 0 0 64px";
    }};
    max-width: ${(p) => {
      if (p.variant === "light") {return "600px";}
      if (p.variant === "white") {return "518px";}
      return "unset";
    }};
  }
`;

export const RestyledTitle = styled(Title)`
  margin-bottom: ${(p) => (p.variant === "white" ? 24 : 16)}px !important;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: ${(p) => (p.variant === "white" ? 36 : 24)}px !important;
  }
`;

export const Description = styled.p`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.l};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 170%;
  //   margin-bottom: 32px;
  margin-bottom: 16px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    // margin-bottom: 0;
    margin-bottom: 24px;
  }
`;

export const ListTitle = styled.p`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.l};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;
  margin-bottom: 16px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 24px;
  }
`;

export const DottedList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DottedListItem = styled.li`
  display: flex;
  align-items: flex-start;
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.l};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 165%;
  box-sizing: content-box;
  div {
    flex: none;
    margin-right: 12px;
    img {
      width: 14px;
      height: 14px;
    }
  }
`;

export const TableContainer = styled(Container)`
    width: 100%;
    overflow: hidden;
    height: 100%;
    padding-right: 0;
    padding-left: 15px;

    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      padding: 0 15px;
    }
  }`;

export const TableBox = styled.div`
  box-shadow: ${(p) => p.theme.shadows.card};
  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    background-color: white;
    padding-left: 16px;
    padding-bottom: 16px;
    border-radius: 12px 0 0 12px;
  }
`;

export const StepItem = styled.div`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: ${(p) => p.theme.fontStyle.normal};
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const StepTitle = styled.span`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 120%;
  margin-bottom: 24px;
`;

export const StepDescription = styled.p`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.l};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 165%;
  margin: 0;
`;
