import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Icon } from "../../../../components/ui/Icon/Icon";
import { Title } from "../../../../components/ui/Title/Title";

export const DnsTestContainer = styled(Container)`
  // padding-top: 120px;
  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const DnsTestCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
  margin-top: -156px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 36px;
    margin-top: -200px;
    flex-direction: row;
    min-height: 560px;
    border-radius: 12px;
    background: ${(p) => p.theme.colors.white};
    box-shadow: ${(p) => p.theme.shadows.card};
  }
`;

export const InfoContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.card};
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 24px;
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    width: 360px;
    max-width: 360px;
    min-width: 360px;
    margin-right: 36px;
    margin-left: 0;
    margin-bottom: 0;
    box-shadow: none;
    padding: 0;
  }
`;

export const InfoCard = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 24px;
  border-radius: 12px;
  background: #f6f7f9;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }
`;

export const InfoImageWrapper = styled.div`
  min-height: 185px;
      @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
        min-height: 244px;
      }
    }
`;

export const InfoImage = styled.img`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    width: 310px;
    margin-bottom: 24px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 16px;
`;

export const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 150%;
`;

const ldsRoller = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const AnalyzingWrapper = styled.div`
  display: ${({ mobileDirection }) => (mobileDirection ? "block" : "none")};
  margin-bottom: ${({ mobileDirection }) => (mobileDirection ? "16px" : "0")};

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    display: ${({ mobileDirection }) => (mobileDirection ? "none" : "block")};
    height: ${({ mobileDirection }) => (mobileDirection ? "100%" : "auto")};
  }
`;

export const RestyledTitle = styled(Title)`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  width: auto;
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : "0")} !important;

  ${({ hide }) => hide && "color: transparent;"}

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 0;
  }
`;

export const AdditionalText = styled.div`
  display: flex;
  align-items: center;
  ${({ hide }) => hide && "color: transparent;"}
`;

export const LoaderIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  color: #3a4763;
  animation: linear 1.5s infinite ${ldsRoller};
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    margin-top: 0;
    height: 390px;
  }
`;

export const ResultsWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    max-width: 716px;
  }
`;

export const IPWrapper = styled.div`
  padding: 16px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.card};
  margin-bottom: 24px;
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const IPInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const FlagWrapper = styled.div`
  display: flex;
  felx-direction: row;
  align-items: center;
  gap: 12px;

  & > img {
    width: 36px;
    height: 26px;
    font-size: 0;
    flex: none;
  }
`;

export const IPAdress = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.title};
  font-size: ${({ theme }) => theme.fontSizes.xll};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 120%;
  color: ${({ theme }) => theme.colors["neutral-800"]};
`;

export const SecurityReport = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors["dark-500"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 140%;

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    margin-bottom: 24px;
  }

  & > svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors["neutral-400"]};
  }
`;
